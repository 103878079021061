import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import VueGtag from 'vue-gtag';
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-26080797-1' },
  },
  router
);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import ZoomOnHover from 'vue-zoom-on-hover';
Vue.use(ZoomOnHover);

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

import LetItSnow from 'vue-let-it-snow';
Vue.use(LetItSnow);

import 'vue-burger-button/dist/vue-burger-button.css';
import BurgerButton from 'vue-burger-button';

Vue.component('burger-button', BurgerButton);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
